// CSS Files
import '../css/f-public/f-public.scss';

// JS Files
const $ = require('jquery');
Window.prototype.$ = $; //HERE IS MY SOLUTION (Without this line it doesn't work!)

import '@popperjs/core';
import 'admin-lte/plugins/bootstrap/js/bootstrap';

$(document).on('select2:open', () => {
    document.querySelector('.select2-search__field').focus();
});